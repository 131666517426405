.hide-DraftEditorPlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.block-todo {
  position: relative;
  padding: 0px 20px;
}

.block-todo input[type="checkbox"] {
  position: absolute;
  left: 0px;
  cursor: pointer;
}
